<template>
  <div id="app">
    <div class="header">
      <img src="https://i.imgur.com/E9fz9pu.png" alt="icon" class="icon" />
      <h1>User Details</h1>
    </div>
    <div class="table-container">
      <table v-if="users">
        <thead>
          <tr>
            <th>Name</th>
            <th>From</th>
            <th>To</th>
            <th>Scheduled Arrival</th>
            <th>Actual Arrival</th>
            <th>Status</th>
            <th>Department</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.viberId">
            <td>{{ user.name }}</td>
            <td>{{ user.from }}</td>
            <td>{{ user.to }}</td>
            <td>{{ new Date(user.scheduled_arrival).toLocaleTimeString() }}</td>
            <td>{{ user.actual_arrival === null ? "" : new Date(user.actual_arrival).toLocaleTimeString() }}</td>
            <td :class="{ 'late-status': user.status === 'late' }">{{ user.status }}</td>
            <td>{{ user.department }}</td>
          </tr>
        </tbody>
      </table>
      <p v-else>Error fetching user details: {{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [],
      errorMessage: ''
    };
  },
  methods: {
    formatScheduledArrival(value) {
      // Return the formatted value here
      // This is a placeholder and will need to be replaced
      return value;
    },
    formatActualArrival(value) {
      // Return the formatted value here
      // This is a placeholder and will need to be replaced
      return value;
    }
  },
  mounted() {
    fetch(`https://report-bot-g2ki.onrender.com/user_details`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error fetching user details');
        }
        return response.json();
      })
      .then((data) => {
        this.users = data;        
        // console.log(data);
      })
      .catch((error) => {
        this.errorMessage = error.message;
      });
  },
};
</script>


<style>
#app {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  background: #F5F5F5;
  padding: 20px;
}

.table-container {
  margin-top: 40px;
  padding: 20px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

table {
  border-collapse: collapse;
  width: 100%;
  background: white;
}

thead {
  background: linear-gradient(to right, #857FD2, #B1A8E8);
}

th,
td {
  border-bottom: 1px solid #ddd;
  padding: 15px;
  text-align: left;
}

th {
  color: white;
  background-clip: text;
}

.late-status {
  color: red;
  font-weight: bold;
}
tbody tr:nth-child(odd) {
  background-color: #ffffff;
}
tbody tr:nth-child(even) {
  background-color: #f6f1ff;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.icon {
  width: 30px;
  height: 30px;
}
h1 {
  color: #7065CD;
}
</style>
